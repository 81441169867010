<template>
  <main class="page-container" :id="isFindId ? 'sme-recovery-id':'sme-recovery-password'">
    <div class="auth-component">
      <header class="auth-header">
        <h2 class="title">{{ isFindId ? '아이디' : '비밀번호' }} 찾기</h2>
      </header>
      <div class="auth-body">
        <div class="auth-content">
          <h4 class="title">본인 명의의 휴대폰 번호로 인증 후 {{ isFindId ? '아이디를 알려드려요' : '비밀번호를 변경하세요' }}</h4>
          <small class="subtext">본인 주민등록번호로 가입 된 휴대폰 번호</small>
          <table v-if="isFindId && isSelfCheck" class="table gachi-auth-table mt-3">
            <thead class="tb-header">
            <tr>
              <th class="text-center">아이디</th>
              <th class="text-center">가입일자</th>
            </tr>
            </thead>
            <tbody class="tb-body">
            <tr v-for="(item, idx) in items" :key="idx">
              <td>{{ item.lgnId }}</td>
              <td>{{ regDtTimeFormat(item.regDt) }}</td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-if="!isFindId" class="auth-forms">
          <div class="form-row">
            <div class="form-celltd">
              <div class="form-group">
                <input v-model="lgnId" type="text" class="form-control" placeholder="아이디" :readonly="isSelfCheck"/>
                <template v-if="isSelfCheck">
                  <input v-model="userPasswd" type="password" class="form-control mt-1" placeholder="새 비밀번호 입력" />
                  <div class="text-left">
                    <small class="small-text" style="color: red" v-if="!passwordInit && !isPasswordRegx && userPasswd.length > 0">* 영문, 숫자, 특수문자 포함 8-16자리로 입력해주세요.</small>
                  </div>
                  <input v-model="userPasswdChk" type="password" class="form-control mt-1" placeholder="새 비밀번호 확인" />
                  <div class="text-left">
                    <small class="small-text" style="color: red" v-if="!isPasswordCheck && userPasswdChk.length > 0">* 비밀번호가 일치하지 않습니다.</small>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="auth-buttons">
          <button v-if="!isSelfCheck" class="btn btn-xl btn-primary" @click="checkMe">
            <span class="text">휴대폰 본인 인증</span>
          </button>
          <button v-else-if="isFindId" class="btn btn-xl btn-primary" @click="clickMove">
            <span class="text">비밀번호 찾기</span>
          </button>
          <button v-else-if="!isFindId" class="btn btn-xl btn-primary" @click="clickChangePassword">
            <span class="text">비밀번호 재설정</span>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>

export default {
  name: 'FindInfo',
  data: ()=>({

  }),
  computed: {

  },
  watch: {

  },
  methods: {

  }
};
</script>
